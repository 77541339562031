<template>
  <div class="w-full">
    <div class="w-full flex" style="flex-flow: row wrap;">
      <p class="label-input w-full">{{label}}</p>
      <div class="w-full mt-3 buttons-container" :class="containerClass">
        <button class="rounded px-3 py-2 mb-3 text-light" :class="`${buttonClass}`" :id="button.id" v-for="(button, i) in buttons" :key="i"
          v-bind:style="{
            backgroundColor: buttonSelected == button.value ? activeColorBackground : inactiveColorBackground, 
            color: buttonSelected == button.value ? activeColorText : inactiveColorText,
            borderColor: buttonSelected == button.value ? activeBorderColor : inactiveBorderColor,
            borderWidth: 'thin'
          }" @click="changeButton(button.value)">{{button.text}}</button>
      </div>
    </div>
    <link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet" />
  </div>
</template>

<script scoped>
import('@/assets/css/referredperson.css')
export default {
  data() {
    return {
      buttonSelected: '',
      activeColorBackground: '#810042 !important',
      inactiveColorBackground: '#FFFFFF !important',
      activeColorText: '#FFFFFF !important',
      inactiveColorText: '#810042 !important',
      activeBorderColor: '#810042',
      inactiveBorderColor: '#810042'
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => []
    },
    selectFirstButton: {
      type: Boolean,
      default: false
    },
    containerClass:{
      type: String,
      default: ""
    },
    buttonClass:{
      type: String,
      default: () => 'w-full md:w-40'
    }
  },
  name: 'SelectTwoOptionsComponent',
  beforeMount() {
    if (this.selectFirstButton) {
      if (this.buttons.length > 0) {
        this.changeButton(this.buttons[0].value);
      }
    }
  },
  methods: {
    changeButton(value) {
      this.buttonSelected = value;
      this.$emit('choose-button', value);
    },
    changeDontbutton(value) {
      this.buttonSelected = value;
    }
  }
}
</script>

<style scoped>
.buttons-container > button {
  flex: 1 1 auto;
  margin-right: 5px;  /* and that, will result in a 10px gap */
}
</style>